"use strict";
(() => {
  // index.js
  var deferCssData = {};
  var ignoreKeys = ["onDefer"];
  function deferCss(links, mountId = "defer-css") {
    if (!Array.isArray(links))
      links = [links];
    if (deferCssData[mountId] === void 0) {
      deferCssData[mountId] = { total: 0, loaded: 0 };
    } else {
      deferCssData[mountId].total += links.length;
    }
    for (const link of links) {
      let linkData = typeof link === "string" ? { href: link } : link;
      let newLink = document.createElement("link");
      newLink.rel = "stylesheet";
      newLink.onload = function() {
        deferCssData[mountId].loaded++;
        if (typeof linkData.onDefer === "function")
          linkData.onDefer(linkData);
      };
      for (const $scriptKey of Object.keys(linkData)) {
        if (ignoreKeys.includes($scriptKey))
          continue;
        const scriptKey = $scriptKey;
        if ($scriptKey === "crossorigin") {
          newLink.crossOrigin = linkData[scriptKey];
        } else {
          newLink[scriptKey] = linkData[scriptKey];
        }
      }
      let firstLink = document.getElementById(mountId);
      if (firstLink === null || firstLink === void 0) {
        return console.error("DEFER-CSS: no link element with id: <" + mountId + "> found in DOM");
      }
      if (!firstLink.parentNode) {
        return console.error("DEFER-CSS: no parent node found for link element with id: <" + mountId + "> found in DOM");
      }
      firstLink.parentNode.insertBefore(newLink, firstLink);
    }
  }
  function ___getStyleSheet(search, $return = false) {
    const allStyles = document.styleSheets;
    for (let styleSheet of allStyles) {
      if (styleSheet.href !== null) {
        const hasStyle = styleSheet.href.includes(search);
        if (hasStyle) {
          if (typeof $return === "string") {
            if ($return === "stylesheet") {
              return styleSheet;
            } else {
              if (typeof styleSheet[$return] !== "undefined")
                return styleSheet[$return];
            }
          } else {
            return hasStyle;
          }
        }
      }
    }
    return false;
  }
  function hasStyleSheet(search) {
    return ___getStyleSheet(search, false);
  }
  function getStyleSheetProperty(search, $return) {
    return ___getStyleSheet(search, $return);
  }
  function getStyleSheet(search) {
    return ___getStyleSheet(search, "stylesheet");
  }

  // browser.ts
  window.deferCss = deferCss;
  window.deferCssData = deferCssData;
  window.getStyleSheetProperty = getStyleSheetProperty;
  window.getStyleSheet = getStyleSheet;
  window.hasStyleSheet = hasStyleSheet;
})();
